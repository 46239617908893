import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import toSortedOptions from '~/utils/toSortedOptions';

import ProductListResults from './ProductListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

export default function ProductsList({ state, handleChange, clearState }) {
  const classes = useStyles();

  const { pathname } = useLocation();

  const options = {
    productTypes: toSortedOptions(Types.getProductTypes()),
    sites: toSortedOptions(Types.getSites()),
    betTypes: toSortedOptions(Types.getBetTypes()),
  };

  return (
    <>
      <Box mb={2} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Sök"
            variant="outlined"
            value={state.searchValues}
            onChange={e => handleChange('searchValues', e.target.value)}
          />

          <TextField
            className={classes.textField}
            label="Produkttyp"
            variant="outlined"
            value={state.productType}
            select
            onChange={e => handleChange('productType', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.productTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={classes.textField}
            label="Sajt"
            variant="outlined"
            value={state.site}
            select
            onChange={e => handleChange('site', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.sites.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>

          {['bettip', 'ticket'].includes(state.productType) && (
            <TextField
              className={classes.textField}
              label="Spelform"
              variant="outlined"
              value={state.betType}
              select
              onChange={e => handleChange('betType', e.target.value)}
            >
              <MenuItem value="">
                <em>Alla</em>
              </MenuItem>
              {options.betTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          )}
          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clearState()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        <Box ml="auto" minWidth="30%">
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        </Box>
      </Box>
      <Box>
        <ProductListResults state={state} options={options} />
      </Box>
    </>
  );
}

ProductsList.propTypes = {
  state: PropTypes.shape({
    searchValues: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    betType: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
};
