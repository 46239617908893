import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { orderPropType } from '~/propTypes';

import OrderInformation from './OrderInformation';
import OrderLineItems from './OrderLineItems';

export default function OrderDetail({ data }) {
  const order = data.getOrder;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <OrderInformation order={order} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrderLineItems order={order} />
      </Grid>
    </Grid>
  );
}

OrderDetail.propTypes = {
  data: PropTypes.shape({
    getOrder: orderPropType.isRequired,
  }),
};
