import { useQuery } from '@apollo/client';
import {
  CircularProgress,
  Link,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import NoUserData from '~/components/NoUserData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetDistributionsByUser.gql';

export default function Distributions({ user }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  const { error, loading, data } = useQuery(query, {
    variables: { user, page: page, limit: pageSize },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Produkt', 'Utskicksdatum'];

  const res = data?.getDistributionsByUser;

  return (
    <TableList
      title="Utskick"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.distributions.length > 0 ? (
        res.distributions.map(distribution => (
          <TableRow key={distribution.id}>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/editions/${distribution.edition.id}`}
              >
                {distribution.edition.editionLabel}
              </Link>
            </TableCell>
            <TableCell>
              <Time date={new Date(distribution.distributionDate)} />
            </TableCell>
            {distribution.error && (
              <TableCell>
                <Tooltip title={distribution.error}>
                  <WarningIcon />
                </Tooltip>
              </TableCell>
            )}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>
            <NoUserData text="Den här användaren har inte mottagit några utgåvor" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Distributions.propTypes = {
  user: PropTypes.string.isRequired,
};
