import { Box, Button, LinearProgress, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { Types } from 'trhub-utils';
import * as Yup from 'yup';

import toSortedOptions from '~/utils/toSortedOptions';

const validationSchema = Yup.object().shape({
  customerNumber: Yup.number()
    .notRequired()
    .positive('Kundnummer får inte vara negativt.'),
  email: Yup.string().email('Ogiltig mejladress.'),
  mobile: Yup.string().matches(
    /\b\d{10}\b/,
    'Ange ett 10-siffrigt mobilnummer utan mellanslag',
  ),
  postalCode: Yup.string()
    .matches(/^\d+$/, 'Postnummer får enbart innehålla siffror')
    .test(
      'len',
      'Postnummer måste innehålla 5 siffror',
      val => val.length === 5,
    ),
});

export default function UserForm({ initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, isSubmitting, values }) => (
        <Form
          css={({ theme }) => ({
            '> * + *': {
              marginTop: theme.spacing(2),
            },
            '> *': {
              width: '100%',
            },
            '> .MuiFormControl-root': {
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Field
            component={TextField}
            name="firstName"
            label="Förnamn"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="lastName"
            label="Efternamn"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="customerNumber"
            label="Kundnummer"
            type="number"
            value={values.customerNumber || ''}
            variant="outlined"
          />
          <Field
            component={CheckboxWithLabel}
            name="collectionPayee"
            Label={{ label: 'Samlingsbetalare' }}
            type="checkbox"
            checked={values.collectionPayee}
            variant="outlined"
          />
          <Field
            component={TextField}
            name="email"
            label="Email"
            type="email"
            value={values.email || ''}
            variant="outlined"
          />
          <Field
            component={TextField}
            name="mobile"
            label="Mobilnummer"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="careOf"
            label="C/o"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="address"
            label="Adress"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="postalCode"
            label="Postnummer"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="city"
            label="Stad"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="country"
            label="Land"
            variant="outlined"
          />
          <Field
            component={TextField}
            select
            name="roles"
            label="Roller"
            variant="outlined"
            SelectProps={{
              multiple: true,
            }}
          >
            {toSortedOptions(Types.getUserRoles()).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          {isSubmitting && <LinearProgress />}
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Spara
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

UserForm.propTypes = {
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    customerNumber: PropTypes.number,
    collectionPayee: PropTypes.bool,
    email: PropTypes.string,
    mobile: PropTypes.string,
    careOf: PropTypes.string,
    address: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};
