// Expects data to be of either array or object type
export default function toSortedOptions(data) {
  if (Array.isArray(data)) {
    return data
      .sort((a, b) => a.localeCompare(b))
      .map(value => ({ value, text: value }));
  } else {
    return Object.entries(data)
      .sort(([, a], [, b]) => a.localeCompare(b))
      .map(([value, text]) => ({ value, text }));
  }
}
