import { useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';
import { typesPropTypes } from '~/propTypes';
import formatPrice from '~/utils/formatPrice';

import query from './_GetProducts.gql';

export default function ProductListResults({ options, state }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination();

  const { error, data } = useQuery(query, {
    variables: {
      filterProductType: state.productType,
      filterSite: state.site,
      filterBetType: state.betType,
      search: state.searchValues,
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getProducts;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Produkt', 'Produkttyp', 'SKU', 'Pris', 'Moms', 'Site'];

  return (
    <TableList
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.products.length > 0 ? (
        res.products.map(product => (
          <TableRow hover key={product.id}>
            <TableCell>
              <Box alignItems="center" display="flex">
                <Link component={RouterLink} to={`/products/${product.id}`}>
                  {product.name}
                </Link>
              </Box>
            </TableCell>
            <TableCell>
              {
                options.productTypes.find(
                  option => option.value === product.type,
                ).text
              }
            </TableCell>
            <TableCell>{product.sku}</TableCell>
            <TableCell>{formatPrice(product.price)}</TableCell>
            <TableCell align="right">{product.vat}%</TableCell>
            <TableCell>
              {options.sites.find(option => option.value === product.site).text}
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={5} align="center">
            <em>Det finns inga produkter för tillfället.</em>
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

ProductListResults.propTypes = {
  options: PropTypes.shape({
    productTypes: typesPropTypes,
    sites: typesPropTypes,
    betTypes: typesPropTypes,
  }).isRequired,
  state: PropTypes.shape({
    searchValues: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    betType: PropTypes.string.isRequired,
  }).isRequired,
};
