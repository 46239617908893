import { useCallback, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Edition from './Edition';
import EditionAdd from './EditionAdd';
import EditionList from './EditionList';

export default function Editions() {
  const { path } = useRouteMatch();

  const initialState = {
    selectedEditionYear: -1,
    selectedSKU: 'all',
  };

  const [state, setState] = useState(initialState);

  // Handlechange for search & filter component
  const handleChange = useCallback((type, value) => {
    setState(prev => {
      if (type === 'selectedEditionYear') {
        return { ...prev, selectedEditionYear: value };
      }
      if (type === 'selectedSKU') {
        return { ...prev, selectedSKU: value };
      }
    });
  }, []);

  const clearState = () => {
    setState({ ...initialState });
  };

  return (
    <>
      <Breadcrumb name="Utgåvor" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <EditionList
            selectedEditionYear={state.selectedEditionYear}
            selectedSKU={state.selectedSKU}
            handleChange={handleChange}
            clearState={clearState}
          />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <EditionAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:edition`}>
          <Edition />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
