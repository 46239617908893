import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  TextField as TextFieldCore,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { format, parseISO } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { Types } from 'trhub-utils';
import * as Yup from 'yup';

import ErrorMessage from '~/components/ErrorMessage';
import { NumberField } from '~/components/NumberField';
import { campaignType } from '~/propTypes';
import query from '~/utils/_GetAllProducts.gql';
import toSortedOptions from '~/utils/toSortedOptions';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ett namn måste anges.'),
  discount: Yup.object()
    .shape({
      type: Yup.string().required('En rabattyp måste anges.'),
      value: Yup.number()
        .required('Rabatt måste anges.')
        .moreThan(0, 'Rabatt måste vara ett positivt heltal.')
        .when('type', {
          is: value => value === 'percentage',
          then: Yup.number().lessThan(101, 'Procent får ej överstiga 100.'),
        }),
    })
    .when('products', {
      is: value => value.length > 1,
      then: Yup.object({
        type: Yup.string()
          .required('Rabattypen måste vara av typ Prisavdrag eller Fast pris..')
          .oneOf(
            ['fixedDiscount', 'fixedPrice'],
            'Rabattypen måste vara av typ Prisavdrag eller Fast pris.',
          ),
        value: Yup.number()
          .required('Rabatt måste anges.')
          .moreThan(0, 'Rabatt måste vara ett positivt heltal.'),
      }),
    }),
  site: Yup.string().required('En sajt måste anges.'),
  type: Yup.string().required('En kampanjtyp måste anges.'),
  expireDate: Yup.date().when('type', {
    is: value => value === 'timebound',
    then: Yup.date('Ogiltigt datum.')
      .min(format(new Date(), 'yyyy-MM-dd'), 'Ogiltigt datum.')
      .nullable(),
  }),
  duration: Yup.number().when('products', {
    is: value => {
      !value.expireDate &&
        value.some(product => product.type === 'subscription');
    },
    then: Yup.number()
      .required('Kampanjlängd måste anges vid prenumerationer.')
      .moreThan(0, 'Kampanjlängden måste vara ett positivt heltal.'),
  }),
  limit: Yup.number()
    .notRequired()
    .positive('Begränsat antal användare måste anges som ett positivt heltal.'),
});

Yup.addMethod(Yup.array, 'atMostOneSubscription', function (errorMessage) {
  return this.test('atMostOne', errorMessage, function (value) {
    const { path, createError } = this;
    return (
      value.filter(product => product.type === 'subscription').length < 2 ||
      createError({ path, message: errorMessage })
    );
  });
});

const productValidation = Yup.object().shape({
  products: Yup.array()
    .atMostOneSubscription('En kampanj får max innehålla en prenumeration.')
    .min(1, 'Minst en produkt eller produktgrupp måste anges.')
    .required('Minst en produkt eller produktgrupp måste anges.')
    .of(
      Yup.object().shape({
        percentage: Yup.number()
          .required('Procentuell inkomstfördelning måste anges.')
          .moreThan(-1, 'Procentuell inkomstfördelning får ej vara negativt.')
          .lessThan(101, 'Procentuell inkomstfördelning får ej överstiga 100.'),
      }),
    ),
});

export default function CampaignForm({
  initialValues,
  addCampaign = false,
  onSubmit,
  totalPercentError,
}) {
  const { loading, error, data } = useQuery(query);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const productList = data?.getAllProducts || [];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        addCampaign
          ? // eslint-disable-next-line unicorn/prefer-spread
            validationSchema.concat(productValidation)
          : validationSchema
      }
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, isSubmitting, values, errors, setFieldValue }) => (
        <Form
          css={({ theme }) => ({
            '> * + *': {
              marginTop: theme.spacing(2),
            },
            '> *': {
              width: '100%',
            },
            '> .MuiFormControl-root': {
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Field
            component={TextField}
            name="name"
            label="Namn"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="code"
            label="Rabattkod"
            variant="outlined"
          />
          <Field
            label="Rabattyp"
            component={TextField}
            select
            name="discount.type"
            variant="outlined"
          >
            {Object.entries(Types.discountTypes).map(([value, label]) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
          {values.discount.type && (
            <NumberField
              name="discount.value"
              label={
                values.discount.type === 'percentage'
                  ? 'Rabatt i procent'
                  : values.discount.type === 'fixedDiscount'
                  ? 'Rabatt i kronor'
                  : 'Fast pris'
              }
              value={values.discount.value}
            />
          )}

          <Field
            component={TextField}
            name="type"
            label="Kampanjtyp"
            variant="outlined"
            select
          >
            {toSortedOptions(Types.getCampaignTypes()).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          {values.type === 'timebound' && (
            <Field
              component={TextField}
              name="expireDate"
              value={format(
                values.expireDate == null
                  ? new Date()
                  : parseISO(values.expireDate),
                'yyyy-MM-dd',
              )}
              InputProps={{
                inputProps: { min: format(new Date(), 'yyyy-MM-dd') },
              }}
              label="Slutdatum"
              variant="outlined"
              type="date"
            />
          )}
          <NumberField
            name="limit"
            value={values.limit || ''}
            label="Begränsat antal användare"
          />
          <Field
            component={TextField}
            select
            name="site"
            label="Sajt"
            variant="outlined"
          >
            {toSortedOptions(Types.getSites()).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          {addCampaign && (
            <>
              <Field
                multiple
                noOptionsText={
                  loading ? 'Laddar in produkter...' : 'Inga träffar.'
                }
                name="products"
                component={Autocomplete}
                filterSelectedOptions
                options={productList}
                getOptionLabel={option => option.name}
                onChange={(_, value) => {
                  setFieldValue(
                    'products',
                    value.map(val => ({
                      ...val,
                      percentage: 100 / value.length,
                    })),
                  );
                }}
                filterOptions={createFilterOptions({
                  limit: 150,
                })}
                renderInput={params => (
                  <TextFieldCore
                    {...params}
                    label="Produkter"
                    variant="outlined"
                    error={errors.products != null}
                  />
                )}
              />
              {errors.products != null && (
                <Box mb={2}>
                  <Alert severity="error">{errors.products}</Alert>
                </Box>
              )}
              {values.products.map(product => {
                const index = values.products.findIndex(
                  item => item.id === product.id,
                );

                return (
                  <Field
                    key={product.id}
                    component={TextField}
                    name={`products[${index}].percentage`}
                    label={`Inkomstfördelning för ${product.name}`}
                    type="number"
                    variant="outlined"
                  />
                );
              })}
              {totalPercentError !== '' && (
                <Box>
                  <Alert severity="error">{totalPercentError}</Alert>
                </Box>
              )}
            </>
          )}
          {values.products != null &&
            values.products.some(
              product => product.type === 'subscription',
            ) && (
              <NumberField
                name="duration"
                value={values.duration || ''}
                label="Kampanjlängd i månader"
              />
            )}

          {isSubmitting && <LinearProgress />}
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Spara
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

const campaignPropType = PropTypes.shape({
  ...campaignType,
  products: PropTypes.arrayOf(PropTypes.string),
});

CampaignForm.propTypes = {
  initialValues: campaignPropType,
  totalPercentError: PropTypes.string,
  addCampaign: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
