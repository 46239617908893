import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import TablePaper from '~/components/TablePaper';
import { campaignPropType } from '~/propTypes';
import formatPrice from '~/utils/formatPrice';

export default function CampaignDetail({ campaignData }) {
  const { pathname } = useLocation();
  const {
    name,
    code,
    discount,
    site,
    duration,
    expireDate,
    products,
    price,
    type,
    limit,
  } = campaignData.getCampaign;

  const tableRows = [
    ['Namn', name],
    ['Rabattkod', code],
    ['Rabattyp', Types.discountTypes[discount.type]],
    [
      'Rabatt',
      discount.type === 'percentage'
        ? `${discount.value} %`
        : `${formatPrice(discount.value)}`,
    ],
    ['Sajt', Types.getSites()[site]],
    ['Pris', `${formatPrice(price)}`],
    ['Kampanjtyp', type === 'fixed' ? 'Fast' : 'Tidsbegränsad'],
  ];

  type === 'timebound' &&
    tableRows.push(['Slutdatum', format(new Date(expireDate), 'yyyy-MM-dd')]);

  duration != null &&
    tableRows.push([
      'Kampanjlängd',
      duration === 1 ? `${duration} månad` : `${duration} månader`,
    ]);

  limit != null && tableRows.push(['Begränsat nyttjande', `${limit} styck`]);

  for (const [i, obj] of products.entries()) {
    tableRows.push(
      [`Produkt ${i + 1}`, obj.product.name],
      [`Inkomstfördelning för ${obj.product.name}`, `${obj.percentage} %`],
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
        <Box mt={2}>
          <Button
            component={NavLink}
            to={`${pathname}/edit`}
            variant="contained"
            color="secondary"
          >
            Redigera
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

CampaignDetail.propTypes = {
  campaignData: PropTypes.shape({
    getCampaign: campaignPropType,
  }).isRequired,
};
