import { useQuery } from '@apollo/client';
import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import toSortedOptions from '~/utils/toSortedOptions';

import query from './_GetEditionFilters.gql';
import EditionListResult from './EditionListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
  },
});

export default function EditionList({
  selectedEditionYear,
  selectedSKU,
  handleChange,
  //clearState,
}) {
  const classes = useStyles();

  const { pathname } = useLocation();

  // Get all editions to populate filters
  const { error, data } = useQuery(query);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const options = {
    editionYears: [],
  };
  const res = data?.getEditionFilters;
  if (res) {
    options.editionYears = res.editionYears;
  }

  return (
    <>
      <Box mb={2} display="flex">
        <Box
          maxWidth="70%"
          display="grid"
          gridAutoFlow="column"
          gridColumnGap="10px"
        >
          <TextField
            className={classes.textField}
            label="Utgivningsår"
            variant="outlined"
            value={selectedEditionYear}
            select
            onChange={e => handleChange('selectedEditionYear', e.target.value)}
          >
            <MenuItem value={-1}>
              <em>Alla</em>
            </MenuItem>
            {options.editionYears.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            select
            value={selectedSKU}
            label="Produkt"
            variant="outlined"
            onChange={e => handleChange('selectedSKU', e.target.value)}
          >
            <MenuItem value="all">
              <em>Alla</em>
            </MenuItem>
            {toSortedOptions(Types.getDistributableProducts()).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          {/* {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clearState()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )} */}
        </Box>
        <Box ml="auto" maxWidth="30%">
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        </Box>
      </Box>
      <Box>
        <EditionListResult
          selectedEditionYear={selectedEditionYear}
          selectedSKU={selectedSKU}
        />
      </Box>
    </>
  );
}

EditionList.propTypes = {
  selectedEditionYear: PropTypes.number.isRequired,
  selectedSKU: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
};
