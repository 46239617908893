import { useQuery } from '@apollo/client';
import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import * as productsQuery from '~/utils/_GetAllProducts.gql';
import * as usersQuery from '~/utils/_GetAllUsers.gql';
import toSortedOptions from '~/utils/toSortedOptions';

import OrderListResults from './OrderListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
  autocomplete: {
    width: 200,
    marginTop: 10,
  },
});

export default function OrderList({ state, handleChange, clearState }) {
  const classes = useStyles();

  const { pathname } = useLocation();

  /**
   * searchValue NOT defined in index file due to history.push in orderListResult
   */
  const [searchValues, setSearchValues] = useState('');

  const {
    loading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery(productsQuery);

  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = useQuery(usersQuery, {
    variables: {
      search: state.user,
    },
    skip: state.user.length < 2,
  });

  if (productsError) {
    return <ErrorMessage error={productsError} />;
  }

  if (usersError) {
    return <ErrorMessage error={usersError} />;
  }

  const productList = productsData?.getAllProducts ?? [];

  const userList = usersData?.getUsers.users ?? [];

  const options = {
    products: productList,
    status: toSortedOptions(Types.getOrderStatus()),
    users: userList,
  };

  return (
    <>
      <Box mb={2} display="flex">
        <Box
          maxWidth="100%"
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Sök order-ID"
            variant="outlined"
            value={searchValues}
            onChange={e => setSearchValues(e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Status"
            variant="outlined"
            value={state.status}
            select
            onChange={e => handleChange('status', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.status.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>

          <Autocomplete
            className={classes.autocomplete}
            noOptionsText={
              state.user.length < 3
                ? 'Ange minst tre tecken.'
                : usersLoading
                ? 'Laddar...'
                : 'Inga träffar.'
            }
            component={Autocomplete}
            inputValue={state.user}
            filterSelectedOptions
            options={userList}
            getOptionLabel={option => option.label || ''}
            onInputChange={(_, value) => handleChange('user', value)}
            filterOptions={createFilterOptions({
              limit: 50,
            })}
            renderInput={params => (
              <TextField {...params} label="Användare" variant="outlined" />
            )}
          />

          <Autocomplete
            className={classes.autocomplete}
            noOptionsText={
              productsLoading ? 'Laddar in produkter...' : 'Inga träffar.'
            }
            component={Autocomplete}
            filterSelectedOptions
            options={productList}
            inputValue={state.product}
            getOptionLabel={option => option.name || ''}
            onChange={(_, value) => handleChange('product', value?.name)}
            filterOptions={createFilterOptions({
              limit: 50,
            })}
            renderInput={params => (
              <TextField {...params} label="Produkt" variant="outlined" />
            )}
          />

          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clearState()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        <Box ml="auto" minWidth="30%">
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        </Box>
      </Box>
      <Box>
        <OrderListResults
          state={state}
          searchValues={searchValues}
          options={options}
        />
      </Box>
    </>
  );
}

OrderList.propTypes = {
  state: PropTypes.shape({
    product: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
};
