import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import UserListResults from './UserListResult';

export default function UserList({ state, handleChange }) {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAllRows = useCallback((checked, users) => {
    setSelectedRows(checked ? users.map(element => element.id) : []);
  }, []);

  const handleSelectRow = useCallback(
    (checked, user) => {
      setSelectedRows(prev =>
        checked ? [...prev, user] : selectedRows.filter(item => item !== user),
      );
    },
    [selectedRows],
  );

  return (
    <>
      <Box mb={2} display="flex" alignItems="flex-end">
        <TextField
          label="Sök"
          variant="outlined"
          value={state.searchValues}
          onChange={e => handleChange('searchValues', e.target.value)}
        />
        <Box ml={2}>
          <FormControlLabel
            label="Endast samlingsbetalare"
            control={
              <Checkbox
                checked={state.onlyCollectionPayee}
                onChange={_ =>
                  handleChange(
                    'onlyCollectionPayee',
                    !state.onlyCollectionPayee,
                  )
                }
              />
            }
          />
        </Box>
        <Box ml="auto">
          <Button
            color="primary"
            disabled={selectedRows.length < 2}
            variant="contained"
          >
            Sammanfoga användare
          </Button>
        </Box>
      </Box>
      <UserListResults
        state={state}
        selectedRows={selectedRows}
        handleSelectAllRows={handleSelectAllRows}
        handleSelectRow={handleSelectRow}
      />
    </>
  );
}

UserList.propTypes = {
  state: PropTypes.shape({
    searchValues: PropTypes.string.isRequired,
    onlyCollectionPayee: PropTypes.bool.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};
