import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import toSortedOptions from '~/utils/toSortedOptions';

import IssueListResult from './IssueListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

export default function IssueList({ state, handleChange, clearState }) {
  const classes = useStyles();

  const { pathname } = useLocation();

  const options = {
    categories: toSortedOptions(Types.getIssueCategories()),
    severities: toSortedOptions(Types.getIssueSeverities()),
    status: toSortedOptions(Types.getIssueStatus()),
  };

  return (
    <>
      <Box mb={2} display="flex">
        <Box
          maxWidth="100%"
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Sök"
            variant="outlined"
            value={state.searchValues}
            onChange={e => handleChange('searchValues', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Allvarsgrad"
            variant="outlined"
            select
            value={state.severity}
            onChange={e => handleChange('severity', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.severities.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Kategori"
            variant="outlined"
            select
            value={state.category}
            onChange={e => handleChange('category', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.categories.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Mottagare"
            variant="outlined"
            select
            value={state.assignee}
            onChange={e => handleChange('assignee', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
          </TextField>
          <TextField
            className={classes.textField}
            label="Status"
            variant="outlined"
            select
            value={state.status}
            onChange={e => handleChange('status', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.status.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clearState()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        <Box minWidth="30%" textAlign="right">
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        </Box>
      </Box>
      <Box>
        <IssueListResult state={state} options={options} />
      </Box>
    </>
  );
}

IssueList.propTypes = {
  state: PropTypes.shape({
    searchValues: PropTypes.string.isRequired,
    severity: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    assignee: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
};
