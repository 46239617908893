import { Redirect, Switch } from 'react-router-dom';

import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Campaign from './Campaign';
import Edition from './Edition';
import Issue from './Issue';
import KeyFigures from './KeyFigures';
import Order from './Order';
import Product from './Product';
import Subscription from './Subscription';
import Users from './Users';

export default function Routes() {
  return (
    <Switch>
      <ErrorBoundRoute path="/users">
        <Users />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/order">
        <Order />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/products">
        <Product />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/subscriptions">
        <Subscription />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/campaigns">
        <Campaign />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/editions">
        <Edition />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/keyfigures">
        <KeyFigures />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/issues">
        <Issue />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/" exact>
        <Redirect to="/users" />
      </ErrorBoundRoute>
    </Switch>
  );
}
