export const Types = {
  getProductTypes() {
    return {
      book: 'Bok',
      ticket: 'Klippkort',
      subscription: 'Prenumeration',
      license: 'License',
      bettip: 'Speltips',
      bettip_template: 'Speltipsmall',
    };
  },

  getLineItemTypes() {
    return {
      product: 'Produkt',
      commerce_coupon: 'Kampanj',
      ticket: 'Klippkort',
      fee: 'Avgift',
      refund: 'Återbetalning',
      shipping: 'Frakt',
      deduction: 'Avdrag',
    };
  },

  getPaymentMethods() {
    return {
      card: 'Kreditkort',
      swish: 'Swish',
      invoice: 'Faktura',
      autogiro: 'Autogiro',
      notification: 'Avisering',
      // free denotes if admin has granted a free subscription to the user
      // this is used prevent recurring service to create invoices
      free: 'Gratis',
    };
  },

  getPaymentIntervals() {
    const MONTHLY = 1;
    const QUARTELY = 3;
    const HALF_YEARLY = 6;
    const YEARLY = 12;

    return {
      [MONTHLY]: 'Månadsvis',
      [QUARTELY]: 'Kvartal',
      [HALF_YEARLY]: 'Halvårsvis',
      [YEARLY]: 'Årsvis',
    };
  },

  getSites() {
    return {
      jokersystemet: 'Jokersystemet',
      overodds: 'Överodds',
      spelvarde: 'Spelvärde',
      storavinster: 'Stora vinster',
      travfakta: 'Travfakta',
      travronden: 'Travronden',
      travronden_spel: 'Travronden Spel',
      travogat: 'Travögat',
    };
  },

  // Timebound defines a date when the campaign ceases to work for the product
  getCampaignTypes() {
    return { fixed: 'Fast', timebound: 'Tidsbegränsad' };
  },

  getBetTypes() {
    return ['DD', 'LD', 'GS75', 'V4', 'V64', 'V65', 'V75', 'V86', 'EXTRAV75'];
  },

  getVATs() {
    return [0, 6, 12, 25];
  },

  getDistributableProducts() {
    return {
      'MC-TRT': 'Travronden',
      'MC-V75': 'Banprogram V75',
      'MC-V86': 'Banprogram V86',
      'MC-TVI': 'Vinternumret',
      'MC-EIN': 'Einars',
    };
  },

  getOrderStatus() {
    return {
      complete: 'Slutförd',
      draft: 'Utkast',
      closed: 'Stängd',
    };
  },

  getPaymentStatus() {
    return {
      init: 'Initierad',
      awaiting: 'Inväntar betalning',
      paid: 'Betald',
      failed: 'Misslyckad',
      credited: 'Manuellt krediterad',
    };
  },

  getUserRoles() {
    return {
      admin: 'Admin',
      'andelsspel:admin': 'Andelsspel: Admin',
      'andelsspel:contributor': 'Andelsspel: Skribent',
      'andelsspel:editor': 'Andelsspel: Redaktör',
      'breedly:admin': 'Breedly: Admin',
      'customer-service': 'Kundtjänst',
      'galopptips:admin': 'Galopptips: Admin',
      'galopptips:contributor': 'Galopptips: Skribent',
      'galopptips:editor': 'Galopptips: Redaktör',
      'joker_sport:admin': 'Joker Sport: Admin',
      'joker_sport:contributor': 'Joker Sport: Skribent',
      'joker_sport:editor': 'Joker Sport: Redaktör',
      'jokersystemet:admin': 'Jokersystemet: Admin',
      'jokersystemet:contributor': 'Jokersystemet: Skribent',
      'jokersystemet:editor': 'Jokersystemet: Redaktör',
      'jokersystemet:freerole': 'Jokersystemet: Gratis',
      'jokersystemet:freerole-premium': 'Jokersystemet: Gratis premium',
      'overodds:admin': 'Överodds: Admin',
      'overodds:contributor': 'Överodds: Skribent',
      'overodds:editor': 'Överodds: Redaktör',
      'spelvarde:admin': 'Spelvärde: Admin',
      'storavinster:admin': 'Storavinster: Admin',
      'trais:admin': 'TRAIS: Admin',
      'trais:contributor': 'TRAIS: Skribent',
      'trais:editor': 'TRAIS: Redaktör',
      'travfakta:admin': 'Travfakta: Admin',
      'travogat:admin': 'Travögat: Admin',
      'travronden:admin': 'Travronden: Admin',
      'travronden:contributor': 'Travronden: Skribent',
      'travronden:editor': 'Travronden: Redaktör',
      'travronden:freerole': 'Travronden: Gratis',
      'travronden_spel:admin': 'Travronden Spel: Admin',
      'travronden_spel:contributor': 'Travronden Spel: Skribent',
      'travronden_spel:editor': 'Travronden Spel: Redaktör',
      'travronden_spel:freerole': 'Travronden Spel: Gratis',
    };
  },

  getIssueCategories() {
    return {
      payment_failure: 'Betalningsmottagning',
      recurring_failure: 'Återkommande betalningar',
      trais_failure: 'TRAIS',
      generate_report_failure: 'Rapporter',
    };
  },

  get discountTypes() {
    return {
      percentage: 'Procentavdrag',
      fixedPrice: 'Fast pris',
      fixedDiscount: 'Prisavdrag',
    };
  },

  getIssueSeverities() {
    return { INFO: 'Info', ERROR: 'Fel', CRITICAL: 'Kritiskt' };
  },

  getIssueStatus() {
    return { NEW: 'Ny', ACKNOWLEDGED: 'Pågående', CLOSED: 'Stängd' };
  },

  /**
   * Projects are used by the economy department to assign products to different accounts.
   * It is needed among products that is to be synced to Billecta.
   */
  getAllProjects() {
    return [
      100, 101, 102, 111, 200, 202, 206, 207, 208, 209, 300, 301, 302, 304, 305,
      306, 307, 308, 309, 900, 901, 902, 903, 904,
    ];
  },

  /**
   * costCenters are used by the economy department to assign products to different accounts.
   * It is needed among products that is to be synced to Billecta.
   */
  getAllCostCenters() {
    return [10, 20, 30, 90];
  },

  /**
   * SKUs of products which grants access to prenly-products
   */
  getPrenlySKUs() {
    return [
      'TR-SPORT-ETIDNING',
      'TR-SPEL-ETIDNING',
      'TR-HELG-ETIDNING',
      'TR-MAGASIN',
    ];
  },

  /**
   * SKUs of products which grants premium-access
   */
  getPremiumSKUs() {
    return ['TR-SPORT', 'TR-SPEL'];
  },

  getCardOrigins() {
    return {
      checkout: 'Checkout',
      drupal: 'Drupal',
    };
  },
};
