import { useApolloClient, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';

import addCampaignMutation from './_AddCampaign.gql';
import CampaignForm from './CampaignForm';

export default function CampaignAdd() {
  const apollo = useApolloClient();
  const [totalPercentError, setTotalPercentError] = useState('');

  const [action] = useMutation(addCampaignMutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });
  const history = useHistory();

  return (
    <>
      <Breadcrumb name="Lägg till ny" />

      <CampaignForm
        initialValues={{
          name: '',
          code: '',
          discount: {
            type: '',
            value: 0,
          },
          type: '',
          limit: undefined,
          site: '',
          expireDate: undefined,
          products: [],
          duration: undefined,
        }}
        totalPercentError={totalPercentError}
        addCampaign
        onSubmit={async (values, { setSubmitting }) => {
          if (
            values.products.reduce((acc, val) => acc + val.percentage, 0) !==
            100
          ) {
            setTotalPercentError(
              'Den totala procentuella inkomstfördelningen måste vara 100%.',
            );
          } else {
            const { products, duration, expireDate, limit, discount, ...rest } =
              values;

            const modifiedValues = {
              ...rest,
              duration: duration === '' ? null : Number(duration),
              expireDate: expireDate === '' ? null : expireDate,
              limit: limit === '' ? null : limit,
              // Reload products to neglect __typedef
              products: products.map(obj => ({
                id: obj.id,
                name: obj.name,
                percentage: obj.percentage,
              })),
              discount: {
                type: discount.type,
                value:
                  discount.type === 'percentage'
                    ? Number(discount.value)
                    : Number(discount.value) * 100,
              },
            };

            const { data } = await action({
              variables: {
                input: modifiedValues,
              },
            });

            setSubmitting(false);
            history.push(`/campaigns/${data.addCampaign.id}`);
          }
        }}
      />
    </>
  );
}
