import {
  Alarm,
  Assessment,
  BarChart,
  FormatListBulleted,
  LocalOffer,
  People,
  Refresh,
  ShoppingCart,
  Timeline,
} from '@material-ui/icons';

const menus = {
  primary: [
    {
      text: 'Användare',
      path: '/users',
      icon: People,
    },
    {
      text: 'Ordrar',
      path: '/order',
      icon: ShoppingCart,
    },
    {
      text: 'Produkter',
      path: '/products',
      icon: People,
    },
    {
      text: 'Prenumerationer ',
      path: '/subscriptions',
      icon: Refresh,
    },
    {
      text: 'Kampanjer',
      path: '/campaigns',
      icon: Alarm,
    },
    {
      text: 'Utgåvor',
      path: '/editions',
      icon: LocalOffer,
    },
  ],
  secondary: [
    {
      text: 'Rapporter',
      icon: Assessment,
    },
    {
      text: 'Statistik',
      path: '/statistics',
      icon: BarChart,
    },
    {
      text: 'Ärenden',
      path: '/issues',
      icon: FormatListBulleted,
    },
  ],
  nestedReports: [
    {
      text: 'Nyckeltal',
      path: '/keyfigures',
      icon: Timeline,
    },
  ],
};

export default menus;
