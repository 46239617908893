import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import CampaignListResult from './CampaignListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
  },
});

export default function CampaignList({ searchValues, handleSearchChange }) {
  const classes = useStyles();

  const { pathname } = useLocation();

  return (
    <>
      <Box mb={2} display="flex">
        <Box
          maxWidth="70%"
          display="grid"
          gridAutoFlow="column"
          gridColumnGap="10px"
        >
          <TextField
            className={classes.textField}
            label="Sök"
            variant="outlined"
            value={searchValues}
            onChange={e => handleSearchChange(e.target.value)}
          />
        </Box>
        <Box ml="auto" maxWidth="30%">
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        </Box>
      </Box>
      <Box>
        <CampaignListResult searchValues={searchValues} />
      </Box>
    </>
  );
}

CampaignList.propTypes = {
  searchValues: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
};
