import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import TablePaper from '~/components/TablePaper';

export default function TableList({
  title,
  page,
  pageSize,
  data,
  headers,
  children,
  updatePage,
  updateRowSize,
  selectedRows,
  handleSelectAllRows,
  checkbox,
  button,
}) {
  return (
    <TablePaper title={title} button={button}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {checkbox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.length === pageSize}
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0 && selectedRows.length < pageSize
                    }
                    onChange={event => {
                      const { checked } = event.target;
                      handleSelectAllRows(checked, data.users);
                    }}
                  />
                </TableCell>
              )}
              {headers.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total || 0}
        onChangePage={updatePage}
        onChangeRowsPerPage={e => updateRowSize(e.target.value)}
        page={page - 1}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} av ${count === 10000 ? `fler än ${count}` : count}`
        }
      />
    </TablePaper>
  );
}

TableList.propTypes = {
  title: PropTypes.string,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  children: PropTypes.node,
  updatePage: PropTypes.func.isRequired,
  updateRowSize: PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
  handleSelectAllRows: PropTypes.func,
  checkbox: PropTypes.bool,
  button: PropTypes.node,
};
