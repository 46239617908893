import { useCallback, useState } from 'react';

export default function usePagination(size = 30) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(size);

  // Change page, start on index 1
  const updatePage = useCallback((_, newPage) => {
    setPage(newPage + 1);
  }, []);

  // Update amount of elements of current page
  const updateRowSize = useCallback(pageSize => {
    setPageSize(pageSize);
  }, []);

  return { page, pageSize, updatePage, updateRowSize };
}
