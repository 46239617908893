import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import PropTypes from 'prop-types';

const client = new ApolloClient({
  uri: '/api',
  cache: new InMemoryCache(),
  credentials: 'include',
});

function Apollo({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

Apollo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Apollo;
