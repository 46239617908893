import { useCallback, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import User from './User';
import UserList from './UserList';

export default function Users() {
  const { path } = useRouteMatch();

  const initialState = {
    searchValues: '',
    onlyCollectionPayee: false,
  };

  const [state, setState] = useState(initialState);

  // Handlechange for search component
  const handleChange = useCallback((type, value) => {
    setState(prev => {
      if (type === 'searchValues') {
        return { ...prev, searchValues: value };
      }
      if (type === 'onlyCollectionPayee') {
        return { ...prev, onlyCollectionPayee: value };
      }
    });
  }, []);

  return (
    <>
      <Breadcrumb name="Användare" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <UserList state={state} handleChange={handleChange} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:user`}>
          <User />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
