import { useApolloClient, useMutation } from '@apollo/client';
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Add, GetApp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { editionType } from '~/propTypes';

import GenerateDistributionsMutation from './_GenerateDistributions.gql';
import DistributionListResult from './DistributionListResult';

export default function DistributionList({ hasError, handleHasErrorChange }) {
  const apollo = useApolloClient();
  const { edition } = useParams();
  const [renderList, { loading: mutationLoading }] = useMutation(
    GenerateDistributionsMutation,
    {
      variables: { edition },
      onCompleted: async () => {
        // Ensure all lists are refreshed
        await apollo.reFetchObservableQueries();
      },
    },
  );
  if (mutationLoading) {
    return <p>Genererar distributionslista...</p>;
  }

  return (
    <>
      <Box mb={2} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={hasError}
                onChange={e => handleHasErrorChange(e.target.checked)}
              />
            }
            label="Har felmeddelande"
          />
        </Box>
        <Box ml="auto" maxWidth="30%">
          <Button
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={() => renderList()}
          >
            Generera distributionslista
          </Button>
        </Box>
      </Box>
      <Box>
        <DistributionListResult edition={edition} hasError={hasError} />
      </Box>
      <Box mt={2} display="flex">
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export_distribution_list?edition=${edition}`}
            rel="noreferrer"
          >
            Ladda ner CSV
          </Button>
        </Box>
      </Box>
    </>
  );
}

DistributionList.propTypes = {
  editionData: PropTypes.shape({
    getEdition: editionType,
  }).isRequired,
  hasError: PropTypes.bool.isRequired,
  handleHasErrorChange: PropTypes.func.isRequired,
};
