import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import Address from '~/components/Address';
import TablePaper from '~/components/TablePaper';
import { userPropType } from '~/propTypes';

import Remove from './Remove';

const useStyles = makeStyles({
  root: {
    margin: '10px',
    height: '70px',
    width: '70px',
    fontSize: '24px',
  },
});

export default function Profile({ userData }) {
  const classes = useStyles();

  const {
    id,
    uid,
    name,
    email,
    address,
    mobile,
    customerNumber,
    collectionPayee,
    initials,
    roles,
  } = userData.getUser;

  const { pathname } = useLocation();

  const tableRows = [
    ['Namn', name + (collectionPayee ? ' (Samlingsbetalare)' : '')],
    ['Epost', email],
    ['Mobilnummer', mobile || <em>Uppgift saknas</em>],
    ['Kundnummer', customerNumber],
    ['Adress', <Address key={uid} address={address} />],
    ['Drupal ID', uid],
    ['Roller', roles.map(role => Types.getUserRoles()[role]).join(', ')],
  ];

  return (
    <>
      <TablePaper>
        <TableContainer>
          <Avatar classes={{ root: classes.root }}>{initials}</Avatar>
          <Table>
            <TableBody>
              {tableRows.map(([label, value]) => (
                <TableRow key={label}>
                  <TableCell component="th" scope="row">
                    {label}
                  </TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TablePaper>
      <Box mt={2}>
        <Button
          component={NavLink}
          to={`${pathname}/edit`}
          variant="contained"
          color="secondary"
        >
          Redigera
        </Button>
        <Remove user={id} />
      </Box>
    </>
  );
}

Profile.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
