import { useQuery } from '@apollo/client';
import { CircularProgress, Link, TableCell, TableRow } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';

import query from './_GetSubscriptions.gql';

export default function SubscriptionListResults() {
  const { page, pageSize, updatePage, updateRowSize } = usePagination();

  const { error, data } = useQuery(query, {
    variables: {
      page: page,
      limit: pageSize,
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const res = data?.getSubscriptions;

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Produkt', 'Användare', 'Status'];

  return (
    <TableList
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.subscriptions.length > 0 ? (
        res.subscriptions.map(subscription => (
          <TableRow hover key={subscription.id}>
            {/* <TableCell>
            <Box alignItems="center" display="flex">
              <Link
                component={RouterLink}
                to={`/subscriptions/${subscription.id}`}
              >
                {subscription.id}
              </Link>
            </Box>
          </TableCell> */}
            <TableCell>
              <Link
                component={RouterLink}
                to={`/subscriptions/${subscription.id}`}
              >
                {subscription.product.name}
              </Link>
            </TableCell>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/users/${subscription.user.id}`}
              >
                {subscription.user.email}
              </Link>
            </TableCell>
            <TableCell>{subscription.status}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={3} align="center">
            <em>Det finns inga prenumerationer för tillfället.</em>
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}
