import { useCallback, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Product from './Product';
import ProductAdd from './ProductAdd';
import ProductList from './ProductList';

export default function Products() {
  const { path } = useRouteMatch();

  const initialState = {
    searchValues: '',
    productType: '',
    site: '',
    betType: '',
  };

  const [state, setState] = useState(initialState);

  // Handlechange for search & filter component
  const handleChange = useCallback((type, value) => {
    setState(prev => {
      if (type === 'searchValues') {
        return { ...prev, searchValues: value };
      }
      // Reset betType if productType changes
      if (type === 'productType') {
        return { ...prev, betType: '', productType: value };
      }
      if (type === 'site') {
        return { ...prev, site: value };
      }
      // ProductType must be bettip/ticket in order for betType to be added to filter
      if (type === 'betType') {
        return {
          ...prev,
          betType:
            prev.productType === 'bettip' || prev.productType === 'ticket'
              ? value
              : '',
        };
      }
    });
  }, []);

  const clearState = () => {
    setState({ ...initialState });
  };

  return (
    <>
      <Breadcrumb name="Produkter" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <ProductList
            state={state}
            handleChange={handleChange}
            clearState={clearState}
          />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <ProductAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:product`}>
          <Product />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
