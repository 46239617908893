import { useQuery } from '@apollo/client';
import { CircularProgress, Link, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetEditions.gql';

export default function EditionListResults({
  selectedEditionYear,
  selectedSKU,
}) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination();

  const { error, data } = useQuery(query, {
    variables: {
      filterEditionYear: selectedEditionYear,
      filterSKU: selectedSKU,
      page: page,
      limit: pageSize,
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const res = data?.getEditions;

  if (!res) {
    return <CircularProgress />;
  }

  const headers = [
    'Utgåva',
    'Produkt',
    'Utdelningsdatum',
    'Uttagsdatum',
    'Sista best.datum',
    'Produktionsdatum',
    'Mottagare',
  ];

  return (
    <TableList
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.editions.length > 0 ? (
        res.editions.map(edition => (
          <TableRow hover key={edition.id}>
            <TableCell>
              <Link component={RouterLink} to={`/editions/${edition.id}`}>
                {edition.editionLabel}
              </Link>
            </TableCell>
            <TableCell>
              {Types.getDistributableProducts()[edition.sku]}
            </TableCell>
            <TableCell>
              <Time date={new Date(edition.deliveryDate)} />
            </TableCell>
            <TableCell>
              {edition.distributionDate && (
                <Time date={new Date(edition.distributionDate)} />
              )}
            </TableCell>
            <TableCell>
              {edition.lastOrderDate && (
                <Time date={new Date(edition.lastOrderDate)} />
              )}
            </TableCell>
            <TableCell>
              {edition.productionDate && (
                <Time date={new Date(edition.productionDate)} />
              )}
            </TableCell>
            <TableCell>{edition.distributionCount}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={4} align="center">
            <em>Det finns inga utgåvor för tillfället.</em>
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

EditionListResults.propTypes = {
  selectedEditionYear: PropTypes.number.isRequired,
  selectedSKU: PropTypes.string.isRequired,
};
