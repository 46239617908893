import { useCallback, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Campaign from './Campaign';
import CampaignAdd from './CampaignAdd';
import CampaignList from './CampaignList';

export default function Campaigns() {
  const { path } = useRouteMatch();

  const [searchValues, setSearchValues] = useState('');

  // Handlechange for search component
  const handleSearchChange = useCallback(value => {
    setSearchValues(value);
  }, []);

  return (
    <>
      <Breadcrumb name="Kampanjer" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <CampaignList
            searchValues={searchValues}
            handleSearchChange={handleSearchChange}
          />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <CampaignAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:campaign`}>
          <Campaign />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
