import { useQuery } from '@apollo/client';
import {
  Chip,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import NoUserData from '~/components/NoUserData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';
import formatPrice from '~/utils/formatPrice';

import query from './_GetOrdersByUser.gql';

export default function Orders({ user }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  const { error, loading, data } = useQuery(query, {
    variables: { user, page: page, limit: pageSize },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Datum', 'Order', 'Produkt', 'Pris', 'Status', 'Betalning'];

  const res = data?.getOrdersByUser;

  const orderStatusTypes = Types.getOrderStatus();
  const paymentStatusTypes = Types.getPaymentStatus();

  return (
    <TableList
      title="Ordrar"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.orders.length > 0 ? (
        res.orders.map(order => (
          <TableRow key={order.id}>
            <TableCell>
              <Time date={new Date(order.created)} />
            </TableCell>
            <TableCell>
              <Link component={RouterLink} to={`/order/${order.id}`}>
                {order.id}
              </Link>
            </TableCell>
            <TableCell>{order.label}</TableCell>
            <TableCell>{formatPrice(order.totalPrice)}</TableCell>
            <TableCell>
              <Chip
                color="primary"
                label={orderStatusTypes[order.orderStatus]}
                size="small"
              />
            </TableCell>
            <TableCell>
              <Chip
                color="primary"
                label={
                  order.paymentStatus
                    ? paymentStatusTypes[order.paymentStatus]
                    : 'Info saknas'
                }
                size="small"
              />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>
            <NoUserData text="Den här användaren har inga ordrar" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Orders.propTypes = {
  user: PropTypes.string.isRequired,
};
