import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

const errorType = PropTypes.shape({
  message: PropTypes.string.isRequired,
  networkError: PropTypes.shape({
    result: PropTypes.shape({
      errors: PropTypes.arrayOf(
        PropTypes.shape({
          extensions: PropTypes.shape({
            code: PropTypes.string,
          }),
          message: PropTypes.string.isRequired,
        }),
      ),
    }),
  }),
});

const ErrorDetail = ({ error: { networkError } }) => {
  if (networkError?.result?.errors.length > 0) {
    return (
      <>
        {networkError.result.errors.map(
          ({ extensions: { code } = {}, message }) => (
            <Box key={message} mt={2}>
              {code != null && (
                <Typography variant="subtitle2">{code}</Typography>
              )}
              {message}
            </Box>
          ),
        )}
      </>
    );
  }
  return null;
};

ErrorDetail.propTypes = {
  error: errorType.isRequired,
};

const ErrorMessage = ({ error }) => (
  <Alert severity="error">
    <details>
      <summary>{error.message}</summary>
      <Box mb={2}>
        <ErrorDetail error={error} />
      </Box>
    </details>
  </Alert>
);

ErrorMessage.propTypes = {
  error: errorType.isRequired,
};

export default ErrorMessage;
