import { useQuery } from '@apollo/client';
import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import NoUserData from '~/components/NoUserData';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';

import query from './_GetTicketsByUser.gql';

export default function Tickets({ user }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  const { error, loading, data } = useQuery(query, {
    variables: { user, page: page, limit: pageSize },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Produkt', 'Återstående klipp'];

  const res = data?.getTicketsByUser;

  return (
    <TableList
      title="Klippkort"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.tickets.length > 0 ? (
        res.tickets.map(ticket => (
          <TableRow key={ticket.id}>
            <TableCell>
              {Types.getSites()[ticket.site]} {ticket.betType.join(', ')}
            </TableCell>
            <TableCell>{ticket.remaining}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>
            <NoUserData text="Den här användaren har inga klippkort" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Tickets.propTypes = {
  user: PropTypes.string.isRequired,
};
