import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import DateInputField from '~/components/DateInputField';
import { subscriptionPropType } from '~/propTypes';

import mutation from './_ExtendSubscription.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

const validationSchema = Yup.object().shape({
  dueDate: Yup.date().required('Ett förfallodatum måste anges.'),
});

export default function SubscriptionExtend({ subscriptionData }) {
  const classes = useStyles();

  const apollo = useApolloClient();

  const { subscription } = useParams();
  const [error, setError] = useState('');

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });
  const history = useHistory();

  const { dueDate, paymentMethod } = subscriptionData.getSubscription;

  return (
    <>
      <Breadcrumb name="Förläng prenumeration" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Förlänga förfallodatumet
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Om förfallodatumet på prenumerationen flyttas fram så förändras inte
            priset i samband med förflyttningen, så priset för nuvarande månad
            kommer vara densamma för det förskjutna förfallodatumet.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(3)}px;
            `}
          >
            Om en kampanj är kopplad till prenumerationen så kommer även den att
            flyttas fram i samband med det nya förfallodatumet.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(3)}px;
            `}
          >
            När förfallodatumet väl har ändrats så går det inte att ångra eller
            sätta tillbaka datumet igen.
          </Typography>
          {paymentMethod === 'autogiro' && (
            <Typography
              variant="body2"
              component="p"
              css={`
                margin-top: ${p => p.theme.spacing(3)}px;
              `}
            >
              Då denna prenumeration dras via autogiro så måste förfallodatumet
              ske den 26:e i en kommande månad.
            </Typography>
          )}
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          dueDate: format(new Date(dueDate), 'yyyy-MM-dd'),
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (
            paymentMethod === 'autogiro' &&
            new Date(values.dueDate).getDate() !== 26
          ) {
            setError(
              'Förfallodatumet för autogiro kan endast sättas till den 26e.',
            );
          } else {
            const response = await action({
              variables: {
                id: subscription,
                input: values,
              },
            });
            setSubmitting(false);

            if (response) {
              history.push(`/subscriptions/${subscription}`);
            }
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <DateInputField
              setFieldValue={setFieldValue}
              name="dueDate"
              label="Slutdatum"
              minDate={new Date(dueDate)}
              required
            />
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Spara
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </>
  );
}

SubscriptionExtend.propTypes = {
  subscriptionData: PropTypes.shape({
    getSubscription: subscriptionPropType,
  }).isRequired,
};
