import { useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetUsers.gql';

export default function UserListResults({
  selectedRows,
  handleSelectAllRows,
  handleSelectRow,
  state,
}) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination();
  const { error, data } = useQuery(query, {
    variables: {
      search: state.searchValues,
      onlyCollectionPayee: state.onlyCollectionPayee,
      page: page,
      limit: pageSize,
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const res = data?.getUsers;

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Användare', 'Kundnummer', 'Skapad'];

  return (
    <TableList
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
      selectedRows={selectedRows}
      handleSelectAllRows={handleSelectAllRows}
      checkbox
    >
      {res?.users.length > 0 ? (
        res.users.map(user => (
          <TableRow
            hover
            key={user.id}
            selected={selectedRows.includes(user.id)}
          >
            <TableCell padding="checkbox">
              <Checkbox
                checked={selectedRows.includes(user.id)}
                onChange={event => {
                  const { checked } = event.target;
                  handleSelectRow(checked, user.id);
                }}
              />
            </TableCell>
            <TableCell>
              <Box alignItems="center" display="flex">
                <IconButton
                  aria-label="Avatar"
                  component={RouterLink}
                  to={`/users/${user.id}`}
                >
                  <Avatar src={user.avatarUrl}>{user.initials}</Avatar>
                </IconButton>
                <Box ml={2}>
                  <Link component={RouterLink} to={`/users/${user.id}`}>
                    {user.label}
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell>{user.customerNumber}</TableCell>
            <TableCell>
              <Time date={new Date(user.created)} />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={5} align="center">
            <em>Det finns inga användare för tillfället.</em>
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

UserListResults.propTypes = {
  state: PropTypes.shape({
    searchValues: PropTypes.string.isRequired,
    onlyCollectionPayee: PropTypes.bool.isRequired,
  }).isRequired,
  selectedRows: PropTypes.array.isRequired,
  handleSelectAllRows: PropTypes.func.isRequired,
  handleSelectRow: PropTypes.func.isRequired,
};
