import { useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  Link as RouterLink,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetOrders.gql';

export default function OrderListResults({ state, searchValues }) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { page, pageSize, updatePage, updateRowSize } = usePagination();

  const { error, loading, data } = useQuery(query, {
    variables: {
      filterProduct: state.product,
      filterStatus: state.status,
      filterUser: state.user,
      search: searchValues,
      page: page,
      limit: pageSize,
    },
  });

  // Redirect to Order(detail) if searchValue match an orderid
  useEffect(() => {
    if (searchValues !== '' && data?.getOrders.orders.length === 1) {
      history.push(`${path}/${searchValues}`);
    }
  }, [data?.getOrders, history, path, searchValues]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const res = data?.getOrders;

  const headers = ['id', 'Användare', 'Status', 'Orderdatum', 'Produkter'];

  const orderStatus = Types.getOrderStatus();

  return (
    <TableList
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.orders.length > 0 ? (
        res.orders.map(order => (
          <TableRow hover key={order.id}>
            <TableCell>
              <Box alignItems="center" display="flex">
                <Link component={RouterLink} to={`/order/${order.id}`}>
                  {order.id}
                </Link>
              </Box>
            </TableCell>
            <TableCell>
              <Link component={RouterLink} to={`/users/${order.user.id}`}>
                {order.user.email}
              </Link>
            </TableCell>
            <TableCell>{orderStatus[order.orderStatus]}</TableCell>
            <TableCell>
              <Time date={new Date(order.created)} />
            </TableCell>
            <TableCell>{order.label}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <em>Det finns inga ordrar för tillfället.</em>
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

OrderListResults.propTypes = {
  state: PropTypes.shape({
    product: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
  }).isRequired,
  searchValues: PropTypes.string.isRequired,
};
