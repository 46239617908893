import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Subscription from './Subscription';
import SubscriptionListResults from './SubscriptionListResult';

export default function Subscriptions() {
  const { path } = useRouteMatch();
  return (
    <>
      <Breadcrumb name="Prenumerationer" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <SubscriptionListResults />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:subscription`}>
          <Subscription />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
