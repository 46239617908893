import { useCallback, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Order from './Order';
import OrderList from './OrderList';

export default function Orders() {
  const { path } = useRouteMatch();

  const initialState = {
    product: '',
    status: '',
    user: '',
  };

  const [state, setState] = useState(initialState);

  // Handlechange for search & filter component
  const handleChange = useCallback((type, value) => {
    setState(prev => ({ ...prev, [type]: value === undefined ? '' : value }));
  }, []);

  const clearState = () => {
    setState({ ...initialState });
  };

  return (
    <>
      <Breadcrumb name="Ordrar" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <OrderList
            state={state}
            handleChange={handleChange}
            clearState={clearState}
          />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:order`}>
          <Order />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
